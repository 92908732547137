body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, html {
  font-family: proxima-nova, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*
* Override the default CSS that will screw with the React-Calendar styling we want.
*/
abbr[title], acronym[title] {
  text-decoration: none;
}

input, textarea, select, button {
  font-family: proxima-nova, serif;
}

/*
* Override some specific things in React-Calendar we want to change.
*/
.react-calendar__month-view__weekdays {
  color: #FCBA00;
  font-family: proxima-nova, serif;
  padding-bottom: 15px;
}

.react-calendar__navigation__label {
  font-weight: bold;
  text-transform: uppercase;
  color: #003283;
  letter-spacing: 1px;
  font-size: 16px;
  opacity: 1 !important;
}

.react-calendar__tile--active {
  background-color: transparent;
}

.react-calendar__tile--active:enabled:focus {
  background-color: rgba(252, 186, 0, 0.75);
}

.react-calendar__tile:disabled {
  background-color: transparent;
  opacity: 0.5;
}

.react-calendar__navigation button:disabled {
  background-color: transparent !important;
}

.rc-select-selection__rendered {
  width: 68px;
}
